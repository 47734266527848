<template>
  <br />
  <br />
  <br />
  <!-- Job Detail Section -->
  <section class="job-detail-section">
    <!-- Upper Box -->
    <div class="upper-box">
      <div class="auto-container">
        <!-- Job Block -->
        <div class="job-block-seven">
          <div class="inner-box">
            <div class="content">
              <span class="company-logo">
                <img
                  v-if="logo"
                  style="height: 100px; width: 100px"
                  :src="logo"
                  alt=""
                />
              </span>
              <h4 class="c-text-left">
                <a href="#"
                  >Marketing Executive – Recruitment Services (Hospitality
                  Industry)
                </a>
              </h4>
              <ul class="job-info">
                <!-- <li><span class="icon flaticon-briefcase"></span> Segment</li> -->
                <li>
                  <span class="icon flaticon-map-locator"></span>
                  East London (covering Central London)
                </li>
                <li>
                  <span class="icon flaticon-clock-3"></span> 11 hours ago
                </li>

                <li>
                  <span class="icon flaticon-money"></span>&pound; Sponsorship
                  License Available
                </li>
              </ul>
            </div>

            <div class="btn-box">
              <a href="#how_to_apply" class="theme-btn btn-style-one">Apply</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="job-detail-outer">
      <div class="auto-container">
        <div class="row">
          <div class="content-column col-lg-8 col-md-12 col-sm-12">
            <div class="job-detail">
              <h4>About Us:</h4>
              <p>
                Hive4work Ltd is a leading recruitment firm specializing in the
                hospitality industry. Based in East London, we collaborate with
                top-tier restaurants, hotels, and other hospitality venues
                across Central London. As we continue to grow, we’re looking for
                driven Marketing Executives to join our dynamic team and elevate
                our brand. We offer visa sponsorship for eligible candidates.
              </p>

              <h4>Key Responsibilities:</h4>
              <p>
                &#9679; Develop and execute targeted marketing strategies to
                attract both hospitality businesses and job candidates.
                <br />&#9679; Manage social media campaigns, content creation,
                email marketing, and website updates to enhance our online
                presence. <br />&#9679; Build and maintain relationships with
                restaurants, hotels, and other hospitality venues to promote our
                recruitment services. <br />&#9679; Conduct market research to
                identify trends, opportunities, and emerging demands within the
                hospitality sector. <br />&#9679; Collaborate closely with our
                recruitment team to ensure that marketing efforts are aligned
                with client needs. <br />&#9679; Attend hospitality industry
                events, trade shows, and networking functions to represent
                Hive4work Ltd.
              </p>

              <h4>Qualifications:</h4>
              <p>
                &#9679; Bachelor
                <br />&rsquo;s degree in Marketing, Business, or a related
                field. <br />&#9679; Previous marketing experience in the
                recruitment or hospitality industry (preferred). <br />&#9679;
                Expertise in digital marketing, SEO, and social media
                management. <br />&#9679; Excellent interpersonal and
                communication skills, with a strong ability to network.
                <br />&#9679; Creative, proactive, and driven to achieve results
                in a fast-paced environment. <br />&#9679; Eligible for visa
                sponsorship (if required).
              </p>

              <h4>Why Join Hive4work Ltd?</h4>
              <p>
                &#9679; Competitive salary with performance-based bonuses.
                <br />&#9679; Opportunities for professional development and
                career progression. <br />&#9679; Be part of a growing company
                with a strong presence in London's hospitality industry.
                <br />&#9679; Collaborative and energetic work environment.
                <br />&#9679; Sponsorship opportunities available for
                international candidates.
              </p>
              <div id="how_to_apply">
                <h4>How to Apply</h4>
                <p>
                  If you're ready to make an impact in the hospitality
                  recruitment industry, we'd love to hear from you! Please send
                  your resume and a cover letter to sovel@hive4work.co.uk with
                  the subject line “Marketing Executive Application - [Your
                  Name].”
                </p>
              </div>
            </div>
          </div>

          <div class="sidebar-column col-lg-4 col-md-12 col-sm-12">
            <aside class="sidebar">
              <div class="sidebar-widget">
                <!-- Job Overview -->
                <h4 class="widget-title">Job Overview</h4>
                <div class="widget-content">
                  <ul class="job-overview">
                    <li>
                      <i class="icon icon-location"></i>
                      <h5>Location:</h5>
                      <span>East London (covering Central London) </span>
                    </li>
                    <li>
                      <i class="icon icon-user-2"></i>
                      <h5>Job Title:</h5>
                      <span>Marketing Executive – Recruitment Services </span>
                    </li>
                    <li>
                      <i class="icon icon-clock"></i>
                      <h5>Job Type</h5>
                      <span>Full-time</span>
                    </li>

                    <!-- <li>
                      <i class="icon icon-salary"></i>
                      <h5>Salary Range:</h5>
                      <span>{{ ano_jobs_view.salary_range }}</span>
                    </li> -->
                  </ul>
                </div>

                <!-- Job Skills -->
                <h4 class="widget-title">Keywords</h4>
                <div class="widget-content">
                  <ul class="job-skills">
                    <li v-for="s in ano_jobs_view.keywords" :key="s">
                      <a href="#">{{ s }}</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="sidebar-widget company-widget">
                <div class="widget-content">
                  <div class="company-title">
                    <div class="company-logo">
                      <img
                        v-if="logo"
                        style="height: 50px; width: 50px"
                        :src="logo"
                        alt=""
                      />
                    </div>
                    <h5 class="company-name">{{ ano_com.org_name }}</h5>
                    <img alt="Logo" src="images/logo/logo.png"
                      />
                  </div>

                  <ul class="company-info">
                    <li>
                      Primary industry: <span> Recruitment Services</span>
                    </li>

                    <li>Founded in: <span>2022</span></li>
                    <li>Description: <span>Recruitment Firm</span></li>
                    <li>Location: <span>Startford, London</span></li>
                    <li>Country: <span>England</span></li>
                  </ul>

                  <div class="btn-box">
                    <a href="#" class="theme-btn btn-style-three"
                      >https://www.hive4work.co.uk/</a
                    >
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Job Detail Section -->
</template>

<script>
import axios from "axios";
import vcookie from "vue-cookie";
export default {
  name: "JobView",
  data() {
    return {
      ano_jobs_view: "",
      jobid: "",
      ano_com: "",
      ano_org: "",
      org_logo: "",
      logo: "",
      salary: [],
    };
  },

  methods: {
    get_jobs_view(uuid) {
      axios
        .get("anonymous/job-list/" + uuid + "/")
        .then((res) => {
          this.ano_jobs_view = res.data;
          this.jobid = res.data.id;
          this.ano_com = res.data.recruiter;
          this.ano_org = res.data.recruiter.org_type;
          this.org_logo = res.data.recruiter;
          this.logo = res.data.recruiter.logo.logo;
          this.salary = res.data.salary_range;
          var loader = document.getElementById("loader-small");
              loader.style.display = "none";

          if (res.status == 200) {
            console.log("done");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    applyjob() {
      if (this.jobid != "") {
        const user = JSON.parse(vcookie.get("user"));

        if (user) {
          if (user.user.role == "J") {
            axios
              .post("applicant/job_application/", {
                job_post: this.jobid,
              })
              .then((res) => {
                if (res.status == 201) {
                  this.$emit(
                    "get_message",
                    "You applied for the job successfully !"
                  );
                }
                if (res.status == 200) {
                  alert(res.data.status);
                }
              })
              .catch((error) => {
                alert(error.response.data.status);
              });
          } else {
            this.$emit("get_message", "Sorry ! You are not a candidate !");
          }
        } else {
          this.$router.push({ name: "login" });
          this.$router.push({ name: "login" }).then(() => {
              window.location.reload()
            }).catch((error) => {
              console.log(error)
              
            });
        }
      }
    },
  },

  mounted() {
    var uuid = this.$route.query.uuid;
    var loader = document.getElementById("loader-small");
      loader.style.display = "block";


    if (uuid != null) {
      this.get_jobs_view(uuid);
      window.scrollTo(0, 0);
    }
  },
};
</script>

<style></style>

<template>
  <!--Page Title-->
  <div>
    <br /><br /><br />
    <section class="page-title style-two">
      <div class="auto-container">
        <div class="row">
          <div class="col-lg-7 text-left">
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <div class="sec-title text-center">
                  <h2
                    class="text-white mt-3 text-left"
                    style="font-size: 57px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff"
                  >
                    Find Your Dream Job!
                  </h2>

                  <!-- <div class="text text-white text-left" style="font-size: 30px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff">
                Are you looking for a job!
              </div> -->

                  <div
                    class="text text-white text-left"
                    style="font-size: 25px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff"
                  >
                    Please find your desired one from hundreds new jobs adding
                    daily in Hive4Work
                  </div>
                </div>
              </div>
            </div>

            <div style="background-color: white; border-radius: 10px">
              <div class="row">
                <div
                  class="col-lg-10"
                  style="margin: 0px; padding: 0px; z-index: 10"
                >
                  <div class="form-group text-left px-5 py-3">
                    <span
                      style="color: grey"
                      class="icon flaticon-search-1"
                    ></span>
                    <input
                      style="
                        border-radius: 10px;
                        width: 80%;
                        color: grey;
                        padding-left: 20px;
                      "
                      type="text"
                      
                      name="field_name"
                      placeholder="Job title, keywords, or company"
                    />

                    <span style="height: 100%; background-color: red"></span>
                  </div>
                </div>
                <div
                  id="find-job"
                  class="col-2 pt-3 text-center"
                  style="
                    background-color: #3f4a5a;
                    margin: 0px;
                    padding: 0px;
                    border-radius: 0px 10px 10px 0px;
                    color: white;
                  "
                >
                  <span class="text-center">FIND JOB</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <img src="images/girl2.png" style="height: 100%; width: 100%" />
          </div>
        </div>
      </div>
    </section>
    <!--End Page Title-->

    <!-- Listing Section -->
    <section class="ls-section">
      <div class="auto-container">
        <div class="filters-backdrop"></div>

        <div class="row">
          <!-- Content Column -->
          <div class="content-column col-lg-12">
            <div class="ls-outer">
              <!-- ls Switcher -->
              <div class="ls-switcher">
                <div class="showing-result">
                  <!-- <div class="top-filters">
                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Job Type</option>
                        <option>Full Time</option>
                        <option>Part Time</option>
                        <option>Contractual</option>
                        <option>Internship</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Date Posted</option>
                        <option>Today's Job</option>
                        <option>This week</option>
                        <option>Last week</option>
                        <option>This Month</option>
                        <option>Last 6 months</option>
                        <option>This year</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Experience Level</option>
                        <option>No Experience</option>
                        <option>1 year Experience</option>
                        <option>1-3 years Experience</option>
                        <option>3-6 years Experience</option>
                        <option>6-10 years Experience</option>
                        <option>10+ Years Experience</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Salary estimate</option>
                        <option>Upto 5$/hour</option>
                        <option>Upto 10$/hour</option>
                        <option>Upto 15$/hour</option>
                        <option>Upto 20$</option>
                        <option>Upto 2000$/monthly</option>
                        <option>2000$/monthly</option>
                      </select>
                    </div>
                  </div> -->
                </div>

                <!-- <div class="sort-by">
                  <select class="chosen-select">
                    <option>Job Type</option>
                    <option>Full Time</option>
                    <option>Part Time</option>
                    <option>Contractual</option>
                    <option>Internship</option>
                  </select>

                  <select class="chosen-select">
                    <option>Show 10</option>
                    <option>Show 20</option>
                    <option>Show 30</option>
                    <option>Show 40</option>
                    <option>Show 50</option>
                    <option>Show 60</option>
                  </select>
                </div> -->
              </div>

              <div class="row">
                <!-- Job Block -->
                <div
                  class="job-block col-lg-6"
                >
                  <div @click="openjob(job)" class="inner-box">
                    <div class="content">
                      <span class="company-logo">
                        <img alt="Logo" src="images/logo/logo.png"
                      /></span>
                      <h4 class="c-text-left">Job Title: Marketing Executive – Recruitment Services (Hospitality Industry)
                      </h4>
                      <ul class="job-info">
                        <li>
                          <span class="icon flaticon-briefcase"></span
                          > Hive4work Ltd 
                        </li>
                        <li>
                          <span class="icon flaticon-map-locator"></span> East London (covering Central London)
 
                        </li>
                        <li>
                          <span class="icon flaticon-clock-3"></span> Job Type: Full-time
                        </li>
                        <li>
                          <span class="icon flaticon-money"></span> Sponsorship License Available
                        </li>
                        <li>
                          <!-- <span  class="icon flaticon-money"></span> &pound;{{
                            job.salary_range[0]
                          }}-&pound;{{ job.salary_range[1] }} -->
                        </li>
                      </ul>
                      <ul class="job-other-info">
                      </ul>
                      <button class="bookmark-btn">
                        <span class="flaticon-bookmark"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Pagination -->
              <nav class="ls-pagination">
                <ul>
                  <li class="prev">
                    <a><i class="fa fa-arrow-left"></i
                    ></a>
                  </li>
                  <li>
                    <a>1</a
                    >
                  </li>

                  <li class="next">
                    <a ><i class="fa fa-arrow-right"></i
                    ></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End Listing Page Section -->
  </div>
</template>

<script>
export default {
  name: "JobListView",
  data() {
    return {
  
      ano_jobs: [],

      //Pagenation
      current_page: 1,
      next_page: "",
      next: "",
      previous: "",
      pages: 1,
      total_item: "",
      all_data: {},
    };
  },

  methods: {
    openjob() {
        this.$router.push({ name: "job-single"});
    },

  },

  mounted: function () {
  },
};
</script>

<style></style>
